<template>
  <div>
    <div class="header">
      <img class="bg" src="/img/newsBg.png" alt="" />
      <div class="content">
        <div>
            <div class="desc">新闻动态</div>
            <div class="title" v-html="title"></div>
        </div>  
      </div>
    </div>
    <div class="html">
      <rich :doc="html"></rich>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      html:'',
      title: ''
    }
  },
  components: {
    rich:()=>import("@/components/rich")
  },
  mounted() {
    this.title = this.$route.params.title
    this.html = this.$route.params.html
  }
}

</script>

<style scoped lang="scss">
@import "../header.scss";
.html{
  max-width: 1500px;
  width: 80%;
  margin: 0 auto;
}
</style>
